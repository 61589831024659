<template>
  <div class="section">
    <AnnouncementPanel
        :use-pagination="true"
        :crop-length="200"
        :limit="15"
        :is-expanded="true"
        :in-page="true"
        :can-create="hasPermission('create announcements')"
        :can-delete="hasPermission('delete announcements')"
        :can-edit="hasPermission('edit announcements')"
        :can-administrate="hasPermission('administrate announcements')"
    ></AnnouncementPanel>
  </div>
</template>

<script>

import AnnouncementPanel from "@/components/announcements/AnnouncementsPanel";

export default {
  name: "AnnouncementsIndex",
  components: {
    AnnouncementPanel
  },
  data() {
    return {};
  },
  methods: {
    hasPermission(permission) {
      return !!this.$store.getters["entities/user-permissions/find"](permission);
    },
  }

};
</script>

